import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const POSTS_PER_PAGE = 10;

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("0"); // Default to Pending
  const [sortBy, setSortBy] = useState("createdAt"); // Default sort by created date
  const [sortOrder, setSortOrder] = useState("desc"); // Default sort order
  const [refresh, setRefresh] = useState(new Date());
  const { userId } = useParams();

  useEffect(() => {
    fetchPosts();
  }, [currentPage, searchQuery, refresh, selectedStatus, sortBy, sortOrder]);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get(`/admin/posts`, {
        params: {
          page: currentPage,
          name: searchQuery,
          status: selectedStatus,
          sortBy,
          sortOrder,
          userId,
        },
      });
      const data = response.data;
      setPosts(data.posts);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updatePostStatus = async ({ postId, action }) => {
    try {
      await axiosPrivate.post("/admin/posts/update_status", { postId, action });
      toast.success(`${action} success`);
      setRefresh(new Date()); // Trigger data refresh
    } catch (error) {
      toast.error(`${action} failed`);
    }
  };

  const toggleShowInFeed = async (postId, enable) => {
    try {
      await axiosPrivate.post("/admin/posts/toggle_show_in_feed", {
        postId,
        enable,
      });
      toast.success(`Show in feed ${enable ? "enabled" : "disabled"}`);
      setRefresh(new Date()); // Trigger data refresh
    } catch (error) {
      toast.error(`Failed to toggle show in feed`);
    }
  };

  const handleSortChange = (e) => {
    const [sortBy, sortOrder] = e.target.value.split("-");
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-900 text-white">
      <h1 className="text-2xl font-semibold mb-4">Posts List</h1>
      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search by description..."
          className="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm w-64 px-3 py-2 mr-2 bg-gray-800 text-white"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded"
          onClick={() => fetchPosts()}
        >
          Search
        </button>

        <div className="relative mx-2 items-center">
          <select
            onChange={(e) => setSelectedStatus(e.target.value)}
            value={selectedStatus}
            className="block w-full py-2 pl-3 pr-10 mt-1 text-white bg-gray-800 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <option value="0">Pending</option>
            <option value="1">Approved</option>
            <option value="2">Deleted by User</option>
            <option value="3">Deleted by Admin</option>
            <option value="4">Suspended</option>
          </select>
        </div>

        <div className="relative mx-2 items-center">
          <select
            onChange={handleSortChange}
            className="block w-full py-2 pl-3 pr-10 mt-1 text-white bg-gray-800 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <option value="createdAt-desc">Sort by Date (Newest)</option>
            <option value="createdAt-asc">Sort by Date (Oldest)</option>
            <option value="totalLikes-desc">Sort by Likes (High to Low)</option>
            <option value="totalLikes-asc">Sort by Likes (Low to High)</option>
            <option value="totalComments-desc">
              Sort by Comments (High to Low)
            </option>
            <option value="totalComments-asc">
              Sort by Comments (Low to High)
            </option>
          </select>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className="min-w-full divide-y divide-gray-500">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Username
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Media
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Text
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tags
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Likes
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Comments
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Health
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Views
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {posts.map((post) => (
                <tr key={post._id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.userId.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.type === "Photo" ? (
                      <a href={post.thumbnail_url} target="_blank">
                        <img
                          src={post.thumbnail_url}
                          alt="Post"
                          style={{ width: 100, height: 100 }}
                        />
                      </a>
                    ) : (
                      <a href={post.video_url} target="_blank">
                        {" "}
                        <img
                          src={post.thumbnail_url}
                          controls
                          style={{ width: 100, height: 100 }}
                        />
                      </a>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{post.desc}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{post.tags}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.totalLikes}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.totalComments}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{post.health}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{post.views}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(post.createdAt).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.status === 1 ? "Approved" : "Pending"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap space-x-2">
                    <button
                      onClick={() =>
                        updatePostStatus({
                          postId: post._id,
                          action: "Reject",
                        })
                      }
                      title="Suspend if inappropriate"
                      className="mx-1 px-3 py-1 rounded focus:outline-none bg-red-500 text-white"
                    >
                      Suspend
                    </button>
                    <button
                      onClick={() =>
                        updatePostStatus({
                          postId: post._id,
                          action: "Approve",
                        })
                      }
                      className="mx-1 px-3 py-1 rounded focus:outline-none bg-green-500 text-white"
                      title="Approve to show to followers"
                    >
                      Approve
                    </button>

                    <button
                      onClick={() => toggleShowInFeed(post._id, !post.health)}
                      className={`mx-1 px-3 py-1 rounded focus:outline-none ${
                        post.health ? "bg-blue-500" : "bg-gray-500"
                      } text-white`}
                      title="Enable if Content is good for recomendation system."
                    >
                      {post.health ? "In Feed" : "Enable Feed"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500">
                Showing {posts.length} of {totalPages} pages
              </p>
            </div>
            <div className="flex">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePaginationClick(page)}
                    className={`mx-1 px-3 py-1 rounded focus:outline-none ${
                      currentPage === page
                        ? "bg-blue-500 text-white"
                        : "bg-white text-blue-500 hover:bg-blue-100"
                    }`}
                  >
                    {page}
                  </button>
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PostList;
