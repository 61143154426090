// Header.js

import React, { useState } from "react";
import { FaUser, FaBars, FaSignOutAlt } from "react-icons/fa";
import { BsSun, BsMoon } from "react-icons/bs";
import { useAppContext } from "../../context/AppContext";
import { AppConfig } from "../../Config";

const Header = () => {
  const { isDayMode, toggleDayMode, isSidebarOpen, toggleSidebar, colors } =
    useAppContext();

  return (
    <header
      className={`p-4 fixed w-full top-0 z-10 shadow-md ${
        isDayMode ? "bg-gray-800 text-white" : "bg-white text-blue-500"
      }`}
    >
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <div className="text-xl font-bold mx-4 flex items-center gap-2">
            <img src="/logo192.png" style={{ width: 42 }} />{" "}
            {AppConfig.APP_NAME}
          </div>
          <div className="flex-1 flex justify-center max-w-2xl">
            <input
              type="text"
              name={`random_${Math.random().toString(36).substring(7)}`}
              id="content"
              autoComplete="off"
              placeholder="Search..."
              className={`px-3 py-2 border rounded-md w-full ${
                isDayMode
                  ? "border-gray-600 text-white"
                  : "border-gray-300 text-blue-500"
              } ${colors.inputBackground}`}
            />
          </div>
          <div className="flex items-center space-x-4">
            {/* Add your icon links here */}
            <FaUser className="cursor-pointer mx-4" />

            <FaBars onClick={toggleSidebar} className="cursor-pointer" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
