import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAppContext } from "../../context/AppContext";
import { axiosInstance } from "../../lib/AxiosInstance";
import { BackendRoutes } from "../../constants/BackendRoute";
import { Link, useNavigate } from "react-router-dom";
import { AppConfig } from "../../Config";
import { toast } from "react-toastify";

const AccountDeleteRequest = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { colors } = useAppContext();
  const navigation = useNavigate();
  const { loginUser, user } = useAppContext();

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    document.title = `Delete your account | ${AppConfig.APP_NAME}`;
  }, []);

  useEffect(() => {
    if (user) {
      navigation("/", { replace: true });
    }
  }, [user]);

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(BackendRoutes.DeleteAccount, {
        reason,
        email,
        password,
      });

      setFormSubmitted(true);
    } catch (error) {
      toast(error.response.data.msg);
      console.error("Error:", error);
    }
  };

  return (
    <div
      className={`flex items-center justify-center h-screen ${colors.background}`}
    >
      {formSubmitted ? (
        <div className="text-white text-xl">
          Account deletion request submitted successfully. your account will be
          deleted within 90 days.
        </div>
      ) : (
        <div
          className={`max-w-md w-full ${colors.cardColor} p-8 rounded-lg shadow-md`}
        >
          <h2 className={`text-2xl font-semibold mb-6 ${colors.text}`}>
            Account deletion request
          </h2>

          <form onSubmit={handleRegister}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className={`block text-sm font-medium ${colors.text}`}
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                className={`mt-1 p-2 w-full border rounded-md ${
                  colors.inputBackground
                } ${colors.border} ${formErrors.email ? "border-red-500" : ""}`}
                onChange={(e) => setEmail(e.target.value)}
              />
              {formErrors.email && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.email.msg}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="password"
                className={`block text-sm font-medium ${colors.text}`}
              >
                Password
              </label>
              <input
                type="pass
          +word"
                id="password"
                placeholder="Password"
                className={`mt-1 p-2 w-full border rounded-md ${
                  colors.border
                } ${colors.inputBackground} ${
                  formErrors.password ? "border-red-500" : ""
                }`}
                onChange={(e) => setPassword(e.target.value)}
              />
              {formErrors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.password.msg}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="reason"
                className={`block text-sm font-medium ${colors.text}`}
              >
                Reason
              </label>
              <textarea
                type="pass
          +word"
                id="reason"
                placeholder="Reason (optional)"
                className={`mt-1 p-2 w-full border rounded-md ${
                  colors.border
                } ${colors.inputBackground} ${
                  formErrors.password ? "border-red-500" : ""
                }`}
                onChange={(e) => setReason(e.target.value)}
              />
              {formErrors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.password.msg}
                </p>
              )}
            </div>

            <button
              type="submit"
              className={`py-2 px-4 text-white rounded-md focus:outline-none focus:ring focus:border-blue-300 ${colors.button}`}
            >
              Delete My Account
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AccountDeleteRequest;
