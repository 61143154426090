import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [statusQuery, setStatusQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { colors } = useAppContext();
  const [refresh, setRefresh] = useState(new Date());

  useEffect(() => {
    fetchUsers();
  }, [currentPage, searchQuery, emailQuery, statusQuery, refresh]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get(
        `/admin/users?page=${currentPage}&username=${searchQuery}&email=${emailQuery}&status=${statusQuery}`
      );
      const data = response.data;
      setUsers(data.payload.data);
      setTotalPages(data.payload.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleEmailChange = (e) => {
    setEmailQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setStatusQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getUserStatus = (status) => {
    if (status === 0) return "Verification Pending";
    if (status === 1) return "Verified";
    if (status === 2) return "Suspended";
    if (status === 3) return "Deleted";
    if (status === 4) return "Suspended by Admin";
  };

  const updateUserStatus = async (userId, status) => {
    try {
      const newStatus = status === 1 ? 2 : 1;
      await axiosPrivate.post("/admin/users/update-status", {
        userId,
        status: newStatus,
      });
      setRefresh(new Date());
      toast("Status updated successfully");
    } catch (error) {
      console.error("Error updating user status:", error);
      toast("Failed to update status");
    }
  };

  const toggleAutoPostApprove = async (userId, canPostAutoApprove) => {
    try {
      await axiosPrivate.post("/admin/users/update-auto-post-approve", {
        userId,
        canPostAutoApprove,
      });
      setRefresh(new Date());
      toast("Auto post approval status updated successfully");
    } catch (error) {
      console.error("Error updating auto post approval status:", error);
      toast("Failed to update auto post approval status");
    }
  };

  const toggleAds = async (userId, showAds) => {
    try {
      await axiosPrivate.post("/admin/users/update-ads", { userId, showAds });
      setRefresh(new Date());
      toast("Ads visibility updated successfully");
    } catch (error) {
      console.error("Error updating ads visibility:", error);
      toast("Failed to update ads visibility");
    }
  };

  const getBanButtonText = (status) => {
    if (status === 0) return "Approve";
    if (status === 1) return "Ban";
    if (status === 2) return "Unban";
  };

  const renderPagination = () => {
    const pagesToShow = 5; // Number of pages to show in the pagination bar
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfPagesToShow);
    let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    // Adjust startPage and endPage to always show `pagesToShow` pages
    if (endPage - startPage + 1 < pagesToShow) {
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    const pages = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );

    return (
      <div className="flex space-x-2">
        {startPage > 1 && (
          <button
            onClick={() => handlePaginationClick(1)}
            className="px-3 py-1 rounded focus:outline-none bg-white text-blue-500 hover:bg-blue-100"
          >
            1
          </button>
        )}
        {startPage > 2 && <span className="px-3 py-1">...</span>}
        {pages.map((page) => (
          <button
            key={page}
            onClick={() => handlePaginationClick(page)}
            className={`px-3 py-1 rounded focus:outline-none ${
              currentPage === page
                ? "bg-blue-500 text-white"
                : "bg-white text-blue-500 hover:bg-blue-100"
            }`}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages - 1 && <span className="px-3 py-1">...</span>}
        {endPage < totalPages && (
          <button
            onClick={() => handlePaginationClick(totalPages)}
            className="px-3 py-1 rounded focus:outline-none bg-white text-blue-500 hover:bg-blue-100"
          >
            {totalPages}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-semibold mb-4">User List</h1>
      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search by username..."
          className={`${colors.cardColor} border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm w-64 px-3 py-2 mr-2`}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <input
          type="text"
          placeholder="Search by email..."
          className={`${colors.cardColor} border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm w-64 px-3 py-2 mr-2`}
          value={emailQuery}
          onChange={handleEmailChange}
        />
        <select
          className={`${colors.cardColor} border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm px-3 py-2`}
          value={statusQuery}
          onChange={handleStatusChange}
        >
          <option value="">All Status</option>
          <option value="0">Verification Pending</option>
          <option value="1">Verified</option>
          <option value="2">Suspended</option>
          <option value="3">Deleted</option>
          <option value="4">Suspended by Admin</option>
        </select>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded ml-2"
          onClick={() => fetchUsers()}
        >
          Search
        </button>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className="min-w-full divide-y divide-gray-500">
            <thead className={colors.cardColor}>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Username
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User Role
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Post Verify
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Posts
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Reels
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Join Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className={`${colors.cardColor} divide-y divide-black-700`}>
              {users.map((user) => (
                <tr key={user._id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link to={"/admin/posts/" + user._id}>{user.username}</Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.userRole === "n" ? "Normal" : "Admin"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.canPostAutoApprove ? "Auto" : "Manual"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.totalPosts}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.totalReels}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(user.createdAt).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {getUserStatus(user.status)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap space-x-2">
                    {user.status !== 3 && (
                      <>
                        <button
                          onClick={() =>
                            toggleAutoPostApprove(
                              user._id,
                              !user.canPostAutoApprove
                            )
                          }
                          className={`mx-1 px-3 py-1 rounded focus:outline-none text-white ${
                            user.canPostAutoApprove
                              ? "bg-blue-500"
                              : "bg-green-500"
                          }`}
                        >
                          {user.canPostAutoApprove
                            ? "Disable Auto Post"
                            : "Enable Auto Post"}
                        </button>
                        <button
                          onClick={() =>
                            updateUserStatus(user._id, user.status)
                          }
                          className="mx-1 px-3 py-1 rounded focus:outline-none bg-blue-500 text-white"
                        >
                          {getBanButtonText(user.status)}
                        </button>
                        <button
                          onClick={() => toggleAds(user._id, !user.showAds)}
                          className={`mx-1 px-3 py-1 rounded focus:outline-none text-white ${
                            user.showAds ? "bg-red-500" : "bg-green-500"
                          }`}
                        >
                          {user.showAds ? "Disable Ads" : "Enable Ads"}
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500">
                Showing {users.length} of {totalPages} pages
              </p>
            </div>
            <div className="flex">{renderPagination()}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserList;
