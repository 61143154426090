import React from "react";
import UserStats from "./UserStats";

export default function Home() {
  return (
    <div className="flex justify-center items-center mt-10">
      <UserStats />
    </div>
  );
}
