// Sidebar.js

import React from "react";
import {
  FaHome,
  FaBookmark,
  FaCog,
  FaSignOutAlt,
  FaBars,
  FaEdit,
  FaUser,
  FaChartBar,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { BsMoon, BsSun } from "react-icons/bs";

const Sidebar = () => {
  const {
    isDayMode,
    toggleDayMode,
    isSidebarOpen,
    toggleSidebar,
    logOut,
    colors,
    user,
  } = useAppContext();

  return (
    <nav
      className={`${
        colors.cardColor
      } text-white h-screen w-64 transition-all duration-300 ${
        isSidebarOpen ? "ml-0" : "-ml-64"
      }`}
    >
      <div className="p-4 mt-20">
        <nav className="flex flex-col space-y-4">
          <Link
            to="/"
            className={`flex items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
              isDayMode ? "bg-gray-700" : "bg-gray-500"
            }`}
          >
            <FaHome />
            <span>Home</span>
          </Link>
          {user && user.userRole === "o" && (
            <>
              <Link
                to="/admin/dashboard"
                className={`flex items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
                  isDayMode ? "bg-gray-700" : "bg-gray-500"
                }`}
              >
                <FaChartBar />
                <span>Stats</span>
              </Link>
              <Link
                to="/admin/posts"
                className={`flex items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
                  isDayMode ? "bg-gray-700" : "bg-gray-500"
                }`}
              >
                <FaEdit />
                <span>Posts</span>
              </Link>

              <Link
                to="/admin/users"
                className={`flex items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
                  isDayMode ? "bg-gray-700" : "bg-gray-500"
                }`}
              >
                <FaUser />
                <span>Users</span>
              </Link>

              <Link
                to="/admin/account_deletion"
                className={`flex items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
                  isDayMode ? "bg-gray-700" : "bg-gray-500"
                }`}
              >
                <FaUser />
                <span>Deletion</span>
              </Link>
              <p>Settings</p>
              <hr />
            </>
          )}

          <div
            onClick={toggleDayMode}
            className={`flex cursor-pointer items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
              isDayMode ? "bg-gray-700" : "bg-gray-500"
            }`}
          >
            {isDayMode ? <BsMoon /> : <BsSun />}
            <span>{isDayMode ? "Dark" : "Light"}</span>
          </div>
          <div
            onClick={logOut}
            className={`flex cursor-pointer items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
              isDayMode ? "bg-gray-700" : "bg-gray-500"
            }`}
          >
            <FaSignOutAlt />
            <span>Logout</span>
          </div>
        </nav>
      </div>
    </nav>
  );
};

export default Sidebar;
