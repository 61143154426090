import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import DashboardLayout from "../layouts/DashboardLayout";
import { AppContextProvider } from "../context/AppContext";
import Login from "../pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Userspage from "../pages/Users";
import PostsPage from "../pages/Posts";
import AccountDeleteRequest from "../pages/AccountDeletionRequest";
import DeletionList from "../pages/AccountDeletion";
import Home from "../pages/Dashboard/Home";

function AppNavigations() {
  return (
    <AppContextProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<DashboardLayout />}>
            <Route path="" element={<Dashboard />} />

            <Route path="/admin/dashboard" element={<Home />} />
            <Route path="/admin/users" element={<Userspage />} />
            <Route path="/admin/posts/:userId?" element={<PostsPage />} />
            <Route path="/admin/account_deletion" element={<DeletionList />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/account/delete" element={<AccountDeleteRequest />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default AppNavigations;
