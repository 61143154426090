import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { toast } from "react-toastify";

const UserStats = () => {
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserStats();
  }, []);

  const fetchUserStats = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get("/admin/user-stats");
      setStats(response.data.payload);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user statistics:", error);
      toast.error("Failed to fetch user statistics");
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading user statistics...</p>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-semibold mb-4 text-white">
        User Statistics
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-4">
        <StatCard title="Total Users" value={stats.totalUsers} />
        <StatCard title="Total Active Users" value={stats.totalActiveUsers} />
        <StatCard title="Total Deleted Users" value={stats.totalDeletedUsers} />
        <StatCard
          title="Total Suspended Users"
          value={stats.totalSuspendedUsers}
        />
        <StatCard
          title="Active Users Last 5 Min"
          value={stats.activeUsersLast5Minutes}
        />
        <StatCard
          title="Active Users Last 24 Hrs"
          value={stats.activeUsersLast24Hours}
        />
        <StatCard
          title="Today's Joined Users"
          value={stats.todaysJoinedUsers}
        />
        <StatCard
          title="Yesterday's Joined Users"
          value={stats.yesterdaysJoinedUsers}
        />
        <StatCard
          title="Last 7 Days Joined Users"
          value={stats.last7DaysJoinedUsers}
        />
        <StatCard
          title="This Month Joined Users"
          value={stats.thisMonthJoinedUsers}
        />
        <StatCard
          title="Last Month Joined Users"
          value={stats.lastMonthJoinedUsers}
        />
        <StatCard
          title="This Year Active Users"
          value={stats.thisYearActiveUsers}
        />
        <StatCard
          title="Last One Year Active Users"
          value={stats.lastOneYearActiveUsers}
        />
      </div>
    </div>
  );
};

const StatCard = ({ title, value }) => (
  <div className="bg-gray-800 shadow-lg rounded-lg text-white p-2 md:p-2 text-xs md:text-xs">
    <h2 className="text-sn font-semibold mb-1">{title}</h2>
    <p className="text-sm">{value}</p>
  </div>
);

export default UserStats;
